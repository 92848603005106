

ul li{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

a, button{
    padding: 10px;
    margin: 10px;;
    text-decoration: none;
    background-color: brown;
    color: #FFF;
    text-align: center;
    border: none;
}
