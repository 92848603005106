.filme-info{
    display: flex;
    flex-direction: column;
    max-width: 800px;
}

.filme-info img{

}

.filme-info button{
    margin: 10px;
    padding: 10px;
}

a{
    text-decoration: none;
    color: #000;
}

.botoes{
    text-align: right;
}