header{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 60px;
    background-color: brown;
}

.logo{
    text-decoration: none;
    font-size: 30px;
    color: #FFF;
    cursor: pointer;
}
.favoritos{
    cursor: pointer;
    text-decoration: none;
    background-color: #FFF;
    border-radius: 5px;
    font-weight: bold;
    color: #000;
    padding: 10px;;
}