.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
}

.lista_filmes{
    margin: 15px;
} 
.lista_filmes article{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px;
}

.lista_filmes strong{
    padding-bottom: 15px;
    font-size: 20px;
}
.lista_filmes img{
    width: 100%;
    max-height: 350px;
}

.lista_filmes a{
    padding: 10px 0 10px 0;
    text-decoration: none;
    background-color: brown;
    color: #FFF;
    width: 100%;
    text-align: center;
}
